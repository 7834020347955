/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { cleanupEmissionConsumptionMarkup } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import reactStringReplace from 'react-string-replace';
import { FootnoteReference } from '@oneaudi/vtp-shared';
import { useStockcarsContext } from '../../hooks/UseStockcarsContext';

export function renderTextWithFootnotesReferences(
  text: string,
): Iterable<React.ReactNode> | string {
  let textWithReplacedFootnotes;

  textWithReplacedFootnotes = reactStringReplace(text, /{ft_([a-zA-Z0-9_-]+)}/g, (match, i) => (
    <FootnoteReference key={i} refId={match} />
  ));

  textWithReplacedFootnotes = reactStringReplace(
    textWithReplacedFootnotes,
    /{{footnote.reference.([a-zA-Z0-9_-]+)}}/g,
    (match, i) => <FootnoteReference key={i} refId={match} />,
  );
  return textWithReplacedFootnotes;
}

export const StockImageConsumptionValues: React.FC = () => {
  const { selectedCar, fallbackConsumptionValue, fallbackEmissionValue } = useStockcarsContext();
  const consumptionString = selectedCar
    ? selectedCar.consumptionAndEmission?.summary?.consumption
    : fallbackConsumptionValue;
  const emissionString = selectedCar
    ? selectedCar.consumptionAndEmission?.summary?.emissionCO2
    : fallbackEmissionValue;

  return (
    <>
      <div style={{ minHeight: '40px' }}>
        <Text variant="copy2">
          {consumptionString
            ? renderTextWithFootnotesReferences(cleanupEmissionConsumptionMarkup(consumptionString))
            : ''}
        </Text>
        <Text variant="copy2">
          {emissionString
            ? renderTextWithFootnotesReferences(cleanupEmissionConsumptionMarkup(emissionString))
            : ''}
        </Text>
      </div>
    </>
  );
};
