import React from 'react';
import { Button, ButtonGroup } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { messages } from '../../i18n/messages';
import { ResultState } from '../StockTeaserLayout/StockTeaserContent';
import { useStockcarsContext } from '../../hooks/UseStockcarsContext';
import { FeatureServiceContext } from '../../FeatureServiceContext';
import { getLinkType } from '../tracking';

const StyledButtonGroup = styled(ButtonGroup)``;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 49%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    width: 100%;
  }
`;
const StyledButtonSolo = styled(Button)`
  box-sizing: border-box;
  width: 100%;
`;

export const StockResultButtons: React.FC<ResultState> = ({ showStock }) => {
  const { loadingState, loadingError, selectedCar, availableStock } = useStockcarsContext();
  const { trackingService } = React.useContext(FeatureServiceContext);
  const newCarsButtonLabel = useI18n(messages.labelNewCars);
  const usedCarsButtonLabel = useI18n(messages.labelUsedCars);

  // Number of Results
  const newCarsResult = selectedCar
    ? selectedCar?.availableStock?.newCars.amount
    : availableStock?.newCars.amount;
  const usedCarsResult = selectedCar
    ? selectedCar?.availableStock?.usedCars.amount
    : availableStock?.usedCars.amount;

  // CTA Urls
  const newCarsUrl = selectedCar
    ? selectedCar?.availableStock?.newCars.url
    : availableStock?.newCars.url;
  const usedCarsUrl = selectedCar
    ? selectedCar?.availableStock?.usedCars.url
    : availableStock?.usedCars.url;

  return (
    <>
      {showStock === 'both' && (
        <StyledButtonGroup
          data-testid="Button group"
          variant="block-buttons"
          block
          spaceStackStart="xl"
          spaceStackEnd="m"
        >
          <>
            {!loadingError && (
              <StyledButton
                href={newCarsUrl}
                onClick={() => {
                  if (trackingService) {
                    trackingService?.track({
                      event: {
                        eventInfo: {
                          eventAction: getLinkType(newCarsUrl),
                          eventName: 'vehicle stock teaser - click on cta',
                        },
                        attributes: {
                          componentName: 'vehicle-stock-teaser',
                          label: newCarsButtonLabel,
                          targetURL: newCarsUrl,
                          clickID: '',
                          elementName: 'button',
                          value: selectedCar?.name,
                          pos: `${newCarsResult}`,
                          relatedElements: [
                            {
                              elementName: 'cta',
                              elementValue: 'car search - new',
                            },
                          ],
                        },
                      },
                    });
                  }
                }}
                data-testid="ctaNewCarslabel"
                variant="primary"
                disabled={loadingState || newCarsResult === 0}
              >
                {newCarsResult} {newCarsButtonLabel}
              </StyledButton>
            )}

            {!loadingError && (
              <StyledButton
                href={usedCarsUrl}
                onClick={() => {
                  if (trackingService) {
                    trackingService?.track({
                      event: {
                        eventInfo: {
                          eventAction: getLinkType(usedCarsUrl),
                          eventName: 'vehicle stock teaser - click on cta',
                        },
                        attributes: {
                          componentName: 'vehicle-stock-teaser',
                          label: usedCarsButtonLabel,
                          targetURL: usedCarsUrl,
                          clickID: '',
                          elementName: 'button',
                          value: selectedCar?.name,
                          pos: `${usedCarsResult}`,
                          relatedElements: [
                            {
                              elementName: 'cta',
                              elementValue: 'car search - used',
                            },
                          ],
                        },
                      },
                    });
                  }
                }}
                data-testid="ctaUsedCarslabel"
                variant="secondary"
                disabled={loadingState || usedCarsResult === 0}
              >
                {usedCarsResult} {usedCarsButtonLabel}
              </StyledButton>
            )}
          </>
        </StyledButtonGroup>
      )}

      {showStock === 'used' && (
        <>
          {!loadingError && (
            <StyledButtonSolo
              href={usedCarsUrl}
              onClick={() => {
                if (trackingService) {
                  trackingService?.track({
                    event: {
                      eventInfo: {
                        eventAction: getLinkType(usedCarsUrl),
                        eventName: 'vehicle stock teaser - click on cta',
                      },
                      attributes: {
                        componentName: 'vehicle-stock-teaser',
                        label: usedCarsButtonLabel,
                        targetURL: usedCarsUrl,
                        clickID: '',
                        elementName: 'button',
                        value: selectedCar?.name,
                        pos: `${usedCarsResult}`,
                        relatedElements: [
                          {
                            elementName: 'cta',
                            elementValue: 'car search - used',
                          },
                        ],
                      },
                    },
                  });
                }
              }}
              data-testid="ctaUsedCarslabel"
              variant="primary"
              disabled={loadingState || usedCarsResult === 0}
            >
              {usedCarsResult} {usedCarsButtonLabel}
            </StyledButtonSolo>
          )}
        </>
      )}

      {showStock === 'new' && (
        <>
          {!loadingError && (
            <StyledButtonSolo
              href={newCarsUrl}
              onClick={() => {
                if (trackingService) {
                  trackingService?.track({
                    event: {
                      eventInfo: {
                        eventAction: getLinkType(newCarsUrl),
                        eventName: 'vehicle stock teaser - click on cta',
                      },
                      attributes: {
                        componentName: 'vehicle-stock-teaser',
                        label: newCarsButtonLabel,
                        targetURL: newCarsUrl,
                        clickID: '',
                        elementName: 'button',
                        value: selectedCar?.name,
                        pos: `${newCarsResult}`,
                        relatedElements: [
                          {
                            elementName: 'cta',
                            elementValue: 'car search - new',
                          },
                        ],
                      },
                    },
                  });
                }
              }}
              data-testid="ctaNewCarslabel"
              variant="primary"
              disabled={loadingState || newCarsResult === 0}
            >
              {newCarsResult} {newCarsButtonLabel}
            </StyledButtonSolo>
          )}
        </>
      )}
    </>
  );
};
