import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useQuery } from '@oneaudi/onegraph-client';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { carlineGroupsWithAmountOfStockCars } from './queries/carline-groups.query';
import { ICarline, transformStockcarData } from '../utils/transformStockcarData';
import { AvailableStock } from './interfaces';
import {
  getArrayFromList,
  getCarlineFiltersForUrl,
  getFilteredCarlines,
} from '../utils/carlineFilter';
import { getContent } from '../utils/content';
import { FeatureAppConfig } from '../FeatureApp';

// eslint-disable-next-line no-shadow
export enum Brand {
  // eslint-disable-next-line no-unused-vars
  Audi = 'A',
}
export interface IStockCarsContext {
  carId: string | undefined;
  loadingState: boolean;
  loadingError: ApolloError | undefined;
  selectedCar: ICarline | null | undefined;
  stockData: ICarline[] | null;
  // eslint-disable-next-line no-unused-vars
  onCarChange: (selectedCarId: string) => void;
  fallbackImage: string;
  fallbackConsumptionValue: string | undefined;
  fallbackEmissionValue: string | undefined;
  availableStock: AvailableStock | null;
  carlineFilter: string[] | undefined;
  faceHeadLine: string | undefined;
}

interface IStock {
  amount: number;
  url: string;
}

interface IAvailableStock {
  newCars: IStock;
  usedCars: IStock;
}

export const getSelectedCar = (
  stockcarData: ICarline[],
  carId: string | undefined,
): ICarline | null => {
  if (!carId) return null;
  const result = stockcarData.find((it: ICarline) => it.identifier.id === carId);
  if (!result) return null;
  return result;
};

export const StockCarsContext = React.createContext({} as IStockCarsContext);

export const StockCarsContextProvider: React.FC<{
  contentService: ContentServiceV1;
  localeService: GfaLocaleServiceV1;
  config: FeatureAppConfig | undefined;
}> = ({ contentService, children, localeService, config }) => {
  const content = getContent(contentService);
  const fallbackImage =
    content?.fallbackImageUrl !== undefined
      ? content?.fallbackImageUrl
      : config?.fallbackImageUrl || '';
  const fallbackConsumptionValue =
    content?.fallbackConsumptionValue !== undefined
      ? content?.fallbackConsumptionValue
      : config?.fallbackConsumptionValue;

  const fallbackEmissionValue =
    content?.fallbackEmissionValue !== undefined
      ? content?.fallbackEmissionValue
      : config?.fallbackEmissionValue;
  const partnerId = content?.partnerId !== undefined ? content.partnerId : config?.partnerId;
  const [carId, setCarId] = useState<string | undefined>(
    content?.defaultModel !== undefined ? content?.defaultModel : config?.defaultModel,
  );
  const carlineFilter =
    content?.preFilteredCarlines !== undefined
      ? getArrayFromList(content?.preFilteredCarlines)
      : getArrayFromList(config?.preFilteredCarlines);
  const [selectedCar, setSelectedCar] = useState<ICarline | null>(null);
  let carData: ICarline[];
  let carDataAll: ICarline[] = [];
  let availableStock: AvailableStock | null = null;
  const faceHeadLine = content?.headLine !== undefined ? content?.headLine : config?.headLine;

  const includeNonConfigurables =
    content?.includeNonConfigurables !== undefined
      ? content?.includeNonConfigurables
      : config?.includeNonConfigurables;

  const country = localeService.countryCode.toLowerCase();
  const language = localeService.language.toLowerCase();

  const { loading, error, data } = useQuery(carlineGroupsWithAmountOfStockCars, {
    variables: {
      country,
      language,
      brand: Brand.Audi,
      targetGroup: '',
      partnerInfo: {
        partnerId,
      },
      includeNonConfigurables,
    },
  });

  const loadingState = loading;
  const loadingError = error;

  const onCarChange = (selectedCarId: string): void => {
    if (carData) {
      setCarId(selectedCarId);
      setSelectedCar(getSelectedCar(carData, selectedCarId));
    }
  };

  if (data?.carlineStructureForStockCars?.carlineGroups) {
    const stockcarData = transformStockcarData(data?.carlineStructureForStockCars?.carlineGroups);
    carDataAll = stockcarData;
    const filteredCarlines = getFilteredCarlines(stockcarData, carlineFilter);
    if (filteredCarlines.length === 1) {
      if (carId !== filteredCarlines[0].identifier.id) {
        setCarId(filteredCarlines[0].identifier.id);
      }
      if (selectedCar !== filteredCarlines[0]) {
        setSelectedCar(filteredCarlines[0]);
      }
    } else if (filteredCarlines.length > 1 && selectedCar !== getSelectedCar(stockcarData, carId)) {
      setSelectedCar(getSelectedCar(stockcarData, carId));
    }

    carData = filteredCarlines;
  }

  if (data?.availableStock) {
    const patchedAvailableStock: IAvailableStock = JSON.parse(JSON.stringify(data.availableStock));
    // for prefiltered Carlines we do not retrieve the entry links when
    // `allCars` is selected in the dropdown
    // in order to provide the entry urls with carline filters set we
    // decided with backend to patch the urls in the frontend.
    // eg: &filter=dealer.00396,carline.a1sb,carline.a3
    // also we need to calculate the sum of available cars from the filtered carlines
    if (carlineFilter?.length !== 0) {
      const carLineFilterString = getCarlineFiltersForUrl(carlineFilter);
      const filtered = getFilteredCarlines(
        transformStockcarData(data?.carlineStructureForStockCars?.carlineGroups),
        carlineFilter,
      );
      patchedAvailableStock.newCars.amount =
        filtered.length > 0
          ? filtered
              .map((item) => item.availableStock?.newCars?.amount || 0)
              .reduce((total, current) => total + current)
          : 0;
      patchedAvailableStock.usedCars.amount =
        filtered.length > 0
          ? filtered
              .map((item) => item.availableStock?.usedCars?.amount || 0)
              .reduce((total, current) => total + current)
          : 0;

      // Below code to avoid duplicate filter in the url : Starts here
      const notIncludedCarLines = (carLineFilterString || '').split(',').filter((carline) => {
        return !data.availableStock.newCars?.url.includes(carline);
      });
      const filteredCarLines = notIncludedCarLines.join(',');
      // end here

      patchedAvailableStock.newCars.url = filteredCarLines
        ? `${data.availableStock.newCars?.url},${filteredCarLines}`
        : data.availableStock.newCars?.url;
      patchedAvailableStock.usedCars.url = filteredCarLines
        ? `${data.availableStock.usedCars?.url},${filteredCarLines}`
        : data.availableStock.usedCars?.url;
    }
    availableStock = patchedAvailableStock;
  }

  const filteredCarlines = getFilteredCarlines(carDataAll, carlineFilter);

  /*   if (filteredCarlines.length === 1) {
    setCarId(filteredCarlines[0].identifier.id);
    setSelectedCar(filteredCarlines[0]);
  } */
  carData = filteredCarlines;
  const carlinesAVailable = carData?.filter(
    (car) => car.availableStock?.newCars.amount !== 0 || car.availableStock?.usedCars.amount !== 0,
  );

  const providerData = {
    carId,
    loadingState,
    loadingError,
    selectedCar,
    stockData: carData,
    onCarChange,
    fallbackImage,
    fallbackConsumptionValue,
    fallbackEmissionValue,
    availableStock,
    carlineFilter,
    includeNonConfigurables,
    faceHeadLine,
  };

  return carlinesAVailable?.length > 0 ? (
    <StockCarsContext.Provider value={providerData}>{children}</StockCarsContext.Provider>
  ) : null;
};
