export interface IStock {
  amount: number;
  url: string;
}

export interface ICarline {
  identifier: {
    id: string;
  };
  name: string;
  exteriorViews: {
    beautyFront: string;
  };
  consumptionAndEmission: {
    summary: {
      consumption: string;
      emissionCO2: string;
      footnoteReference: string;
    };
  } | null;
  availableStock: {
    newCars: IStock;
    usedCars: IStock;
  } | null;
}

export interface ICarlineGroup {
  identifier: {
    id: string;
  };
  name: string;
  carlines: ICarline[];
}

export const transformStockcarData = (carlineGroups: ICarlineGroup[]): ICarline[] => {
  const result = carlineGroups.map((carlineGroup) => {
    return carlineGroup?.carlines;
  });
  // @ts-ignore
  return result.flat();
};
