/**
 * This is the entry point for Feature Hub App integration
 */
import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { AudiFootnoteServiceInterfaceV1 } from '@volkswagen-onehub/audi-footnote-service';
import { AudiPlatformProvider } from '@audi/audi-ui-react-v2';
import I18nContextComponent from '@volkswagen-onehub/audi-i18n-context';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';

import {
  FootnoteReferenceContextProvider,
  FootnoteServiceContextProvider,
  ServicesContextProvider,
} from '@oneaudi/vtp-shared';
import { createLoggerService } from '@volkswagen-onehub/oneaudi-os-adapter-logger';
import { OneGraphProvider } from '@oneaudi/onegraph-client';
import FeatureApp, { FeatureAppConfig } from './FeatureApp';
import { FeatureServiceContext } from './FeatureServiceContext';
import { StockCarsContextProvider } from './one-graphj-api/StockCarsProvider';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger': Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-footnote-service': AudiFootnoteServiceInterfaceV1;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 'audi-tracking-service': TrackingServiceV2;
}

export const name = 'vehicle stock teaser';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:serialized-state-manager': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
    },
    externals: {
      react: '^17.0.2 || ^18.2.0',
      'styled-components': '*',
      '@audi/audi-ui-react-v2': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-content-service': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-footnote-service': '1.0.0',
    },
  },

  create: ({
    featureServices,
    config,
  }: FeatureAppEnvironment<FeatureServiceDependencies, FeatureAppConfig>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const localeService = featureServices['gfa:locale-service'];
    const i18nService = featureServices['dbad:audi-i18n-service'];
    const trackingService = featureServices['audi-tracking-service'] as TrackingServiceV2;
    const contentService = featureServices['audi-content-service'] as ContentServiceV1;
    const footnoteService: AudiFootnoteServiceInterfaceV1 =
      featureServices['audi-footnote-service'];
    const footnoteReferenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3 =
      featureServices['audi-footnote-reference-service'];
    const oneGraphService = featureServices['onegraph-service'];

    const logger = loggerService || createLoggerService(__FEATURE_APP_NAME__);

    if (trackingService) {
      trackingService.featureAppName = name;
    }

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager.getDefaultScopeRefService();

    return {
      render: () => (
        <>
          {oneGraphService.prepareRender(
            (): JSX.Element => (
              <OneGraphProvider client={oneGraphService.getApolloClient()}>
                <AudiPlatformProvider>
                  <ServicesContextProvider
                    featureAppConfig={config}
                    additionalServices={featureServices}
                  >
                    <I18nContextComponent
                      i18nData={i18nService}
                      language={localeService.language}
                      // @ts-ignore
                      featureServices={featureServices}
                      scopes={['oADD']}
                    >
                      <FootnoteServiceContextProvider footnoteService={footnoteService}>
                        <FootnoteReferenceContextProvider
                          footnoteReferenceServiceScopeManager={
                            footnoteReferenceServiceScopeManager
                          }
                        >
                          <FeatureServiceContext.Provider
                            value={{
                              localeService,
                              logger,
                              trackingService,
                              featureHubContentService: contentService,
                              isStandaloneMode: false,
                              footnoteReferenceService,
                            }}
                          >
                            <StockCarsContextProvider
                              contentService={contentService}
                              localeService={localeService}
                              config={config}
                            >
                              <FeatureApp config={config} />
                            </StockCarsContextProvider>
                          </FeatureServiceContext.Provider>
                        </FootnoteReferenceContextProvider>
                      </FootnoteServiceContextProvider>
                    </I18nContextComponent>
                  </ServicesContextProvider>
                </AudiPlatformProvider>
              </OneGraphProvider>
            ),
          )}
          ;
        </>
      ),
    };
  },
};

export default featureAppDefinition;
