import { Loader } from '@audi/audi-ui-react-v2';
import React from 'react';

export interface LoadingState {
  loadingState: boolean;
}

export const StockCarLoader: React.FC<LoadingState> = ({ loadingState }) => {
  return (
    <>
      <div style={{ textAlign: 'center', minHeight: '20px' }}>
        {loadingState && <Loader variant="linear" />}
      </div>
    </>
  );
};
