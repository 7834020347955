/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
import { StockTeaserContentServiceWrapper } from './components/ContentAdapters/StockTeaserContentServiceWrapper';

export type FeatureAppConfig = {
  partnerId?: string;
  groupStock?: boolean;
  defaultModel?: string;
  includeNonConfigurables?: boolean;
  newCarsCtaFallbackUrl?: string;
  usedCarsCtaFallbackUrl?: string;
  showStock?: string;
  fallbackImageUrl?: string;
  fallbackEmissionValue?: string;
  fallbackConsumptionValue?: string;
  preFilteredCarlines?: string;
  headLine?: string;
};

export interface FeatureAppProps extends Omit<AppProps, 'config'> {
  config?: FeatureAppConfig;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ config }: FeatureAppProps) => {
  return <StockTeaserContentServiceWrapper config={config} />;
};

export default FeatureApp;
