import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from '@audi/audi-ui-react-v2';

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const ImageLoaderContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    top: 200px;
  }
`;

const StyledImage = styled.img<CommonImageProps & { isLoaded: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
  margin-left: 0px;
  @media (min-width: ${(props) => props.theme.breakpoints.xs}px) {
    /* breakpoint 320-374 */
    margin-top: 50px;
    margin-bottom: 28px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    /* breakpoint 1024-1339*/
    margin-top: 70px;
    margin-left: 30px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    /* breakpoint 1440-1919 */
    margin-left: 45px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    /* breakpoint 1920*/
    margin-top: 70px;
  }
  transition-timing-function: ${(props) => props.theme.easing};
  opacity: ${(props): number => (!props.isLoaded ? 0 : 1)};
  transition-duration: ${(props): string => (props.isLoaded ? '.4s' : '0s')};
  transition-property: opacity;
`;

type SrcSet = {
  src: string;
  desc: string;
}[];

interface CommonImageProps {
  width?: number;
  height?: number;
  src: string;
  alt?: string;
  loadingAnimation?: 'fade';
  ref?: unknown;
  loading?: string;
  srcSet?: SrcSet | string;
  onLoad?: () => void;
  onError?: () => void;
}

export interface ImageProps extends CommonImageProps {
  srcSet?: SrcSet;
}

export interface PictureProps extends CommonImageProps {
  sources: {
    srcSet?: SrcSet;
    media?: string;
  }[];
}

function srcSetToString(srcSet: SrcSet): string {
  return srcSet.map((item) => `${item.src} ${item.desc}`).join(',');
}

export const WrappedImage = React.forwardRef<HTMLImageElement, ImageProps>(
  (prop: ImageProps, ref) => {
    const { src, srcSet, ...rest } = prop;
    const isSSR = typeof document === 'undefined';

    const [isLoaded, setLoaded] = React.useState(isSSR);
    const [prevSrc, setPrevSrc] = React.useState(src);

    const srcSetString = srcSet ? srcSetToString(srcSet) : undefined;

    useEffect(() => {
      if (prevSrc !== src) {
        setLoaded(false);
        setPrevSrc(src);
      }
    }, [prevSrc, src]);

    return (
      <ImageWrapper>
        <ImageLoaderContainer>{!isLoaded && <Loader />}</ImageLoaderContainer>
        <StyledImage
          ref={ref}
          src={src}
          loading="lazy"
          srcSet={srcSetString}
          isLoaded={isLoaded}
          onLoad={(): void => {
            setTimeout(() => {
              setLoaded(true);
            }, 10);
          }}
          onError={(): void => {
            setTimeout(() => {
              setLoaded(true);
            }, 10);
          }}
          {...rest}
        />
      </ImageWrapper>
    );
  },
);
