import { useContext } from 'react';
import { StockCarsContext } from '../one-graphj-api/StockCarsProvider';

export const useStockcarsContext = () => {
  const stockCarsContext = useContext(StockCarsContext);
  if (!stockCarsContext) {
    throw new Error('useStockcarsContext needs to be used within I18nProvider');
  }
  return stockCarsContext;
};
