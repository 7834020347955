/* eslint-disable react/jsx-props-no-spreading */
/**
 * Context for Feature App with FA Services
 * @see: https://github.com/volkswagen-onehub/audi-feature-app-hello-world/blob/master/src/context.tsx
 */
import { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import * as React from 'react';

export interface ContextValue {
  localeService: GfaLocaleServiceV1;
  logger: Logger;
  isStandaloneMode?: boolean;
  trackingService: TrackingServiceV2;
  featureHubContentService?: ContentServiceV1;
  footnoteReferenceService?: AudiFootnoteReferenceServiceInterfaceV3;
}

export const FeatureServiceContext = React.createContext<ContextValue>({
  trackingService: {} as TrackingServiceV2,
  localeService: {} as GfaLocaleServiceV1,
  logger: console,
});
