import React from 'react';
import { StockResultButtonsFallback } from '../StockResultButtons/StockResultButtonsFallback';

export interface FallbackCTAS {
  newCarsCtaUrl?: string;
  usedCarsCtaUrl?: string;
}

export const StockTeaserLayoutFallback: React.FC<FallbackCTAS> = ({
  newCarsCtaUrl,
  usedCarsCtaUrl,
}) => {
  return (
    <>
      <div style={{ textAlign: 'center' }} />
      <StockResultButtonsFallback newCarsCtaUrl={newCarsCtaUrl} usedCarsCtaUrl={usedCarsCtaUrl} />
    </>
  );
};
