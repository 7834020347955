import React, { useContext, useEffect } from 'react';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { FeatureServiceContext } from '../../FeatureServiceContext';
import { StockTeaserLayout } from '../StockTeaserLayout/StockTeaserLayout';
import { ContentServiceAdaptersProps } from './ContentAdapters.types';
import { getContent } from '../../utils/content';
import { EditorContent } from '../../types/editor';

export interface ContentCCProps {
  groupStock: boolean;
}

export const StockTeaserContentServiceWrapper: React.FC<ContentServiceAdaptersProps> = ({
  config,
}: ContentServiceAdaptersProps) => {
  const { featureHubContentService } = useContext(FeatureServiceContext);

  const [content, setContent] = React.useState<EditorContent | undefined>(
    getContent(featureHubContentService as ContentServiceV1),
  );

  const partnerId: string | undefined =
    content?.partnerId !== undefined ? content.partnerId : config?.partnerId;

  let showStock = content?.showStock || config?.showStock;

  if (showStock === '' || showStock === undefined) {
    showStock = 'both';
  }

  useEffect(() => {
    const callbackListener = () => {
      setContent(getContent(featureHubContentService as ContentServiceV1));
    };
    featureHubContentService?.onContentChange(callbackListener);
    return () => {
      featureHubContentService?.removeOnContentChange(callbackListener);
    };
  }, [featureHubContentService]);

  const newCarsCtaFallbackUrl =
    content?.newCarsCtaFallbackUrl !== undefined
      ? content?.newCarsCtaFallbackUrl
      : config?.newCarsCtaFallbackUrl;
  const usedCarsCtaFallbackUrl =
    content?.usedCarsCtaFallbackUrl !== undefined
      ? content?.usedCarsCtaFallbackUrl
      : config?.usedCarsCtaFallbackUrl;
  const configError = !(newCarsCtaFallbackUrl && usedCarsCtaFallbackUrl);

  return (
    <>
      {!configError && (
        <>
          <StockTeaserLayout
            partnerId={partnerId}
            groupStock={
              content?.groupStock !== undefined ? content?.groupStock : config?.groupStock || false
            }
            defaultModel={
              content?.defaultModel !== undefined ? content?.defaultModel : config?.defaultModel
            }
            newCarsCtaFallbackUrl={newCarsCtaFallbackUrl}
            usedCarsCtaFallbackUrl={usedCarsCtaFallbackUrl}
            showStock={showStock}
          />
        </>
      )}
    </>
  );
};
