import React, { useEffect } from 'react';
import { Layout } from '@audi/audi-ui-react-v2';
import {
  PAGE_MARGIN_XXL,
  PAGE_MARGIN_XL,
  PAGE_MARGIN_L,
  PAGE_MARGIN_M,
  PAGE_MARGIN_S,
  PAGE_MARGIN_XS,
  COLOR_BASE_GREY_5,
} from '@audi/audi-ui-design-tokens';

import styled, { css } from 'styled-components';
import { StockTeaserContent, StockTeaserLayoutProps } from './StockTeaserContent';
import { FeatureServiceContext } from '../../FeatureServiceContext';
import { trackFeatureAppReady, registerImpressionTracking } from '../tracking';

const contentSpacing = css`
  @media (min-width: ${(props) => props.theme.breakpoints.xs}px) {
    --one-content-side-spacing: ${PAGE_MARGIN_XS}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    --one-content-side-spacing: ${PAGE_MARGIN_S}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    --one-content-side-spacing: ${PAGE_MARGIN_M}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    --one-content-side-spacing: ${PAGE_MARGIN_L}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    --one-content-side-spacing: ${PAGE_MARGIN_XL}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    --one-content-side-spacing: ${PAGE_MARGIN_XXL}px;
  }

  margin-inline-start: var(--one-content-side-spacing);
  margin-inline-end: var(--one-content-side-spacing);
`;

const LayoutWithOffset = styled(Layout)`
  ${contentSpacing}
`;

export const StockTeaserLayout: React.FC<StockTeaserLayoutProps> = ({
  showStock,
  partnerId,
  groupStock,
  defaultModel,
  usedCarsCtaFallbackUrl,
  newCarsCtaFallbackUrl,
}) => {
  const backColor = COLOR_BASE_GREY_5;
  const { trackingService } = React.useContext(FeatureServiceContext);

  const ref = React.useRef(null);

  useEffect(() => {
    trackFeatureAppReady(trackingService, partnerId);
    registerImpressionTracking(trackingService, ref, showStock);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: `${backColor}`, minHeight: '520px' }} ref={ref}>
        <LayoutWithOffset direction={{ xs: 'column', l: 'row' }}>
          <StockTeaserContent
            partnerId={partnerId}
            groupStock={groupStock}
            defaultModel={defaultModel}
            usedCarsCtaFallbackUrl={usedCarsCtaFallbackUrl}
            newCarsCtaFallbackUrl={newCarsCtaFallbackUrl}
            showStock={showStock}
          />
        </LayoutWithOffset>
      </div>
    </>
  );
};
