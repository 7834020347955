import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { WrappedImage } from './StockImageWrapper';
// @ts-ignore
import defaultSrc from '../../../../images/DefaultImageVST.svg';
import { ALL_MODELS } from '../StockTeaserLayout/StockTeaserContent';
import { useStockcarsContext } from '../../hooks/UseStockcarsContext';

const ColumnItemPicture = styled.div`
  order: 1;
  width: 100%;
  @media (min-width: 1024px) {
    order: 2;
  }
`;

interface Image {
  src: string;
  alt: string;
  srcSet?: Array<{
    src: string;
    desc: string;
  }>;
}

export const StockImage: React.FC = () => {
  const { loadingState, loadingError, selectedCar, fallbackImage, carId } = useStockcarsContext();

  const defaultImage = useMemo(() => {
    return {
      src: defaultSrc,
      alt: 'Placeholder',
    };
  }, []);

  const imageFallback = {
    src: fallbackImage,
    alt: useI18n({
      defaultMessage: '',
      id: 'nemo.ui.sc.stockteaser.image-description',
    }),
  };

  const [teaserImage, setTeaserImage] = useState<Image>(imageFallback);

  useEffect(() => {
    if (selectedCar === null || carId === ALL_MODELS || loadingError) {
      setTeaserImage(imageFallback);
      return;
    }

    const image = selectedCar?.exteriorViews?.beautyFront;
    setTeaserImage({
      src: image !== undefined ? image : '',
      // TODO: alt attribute not in data set
      alt: '',
      srcSet: [
        {
          src: `${image}?wid=367&mimetype=image/webp`,
          desc: `367w`,
        },
        {
          src: `${image}?wid=767&mimetype=image/webp`,
          desc: `767w`,
        },
        {
          src: `${image}?wid=1023&mimetype=image/webp`,
          desc: '1023w',
        },
        {
          src: `${image}?wid=1440&mimetype=image/webp`,
          desc: `1440w`,
        },
      ],
    });
  }, [carId, fallbackImage, loadingState]);

  return (
    <ColumnItemPicture>
      {!loadingState ? (
        <WrappedImage
          loadingAnimation="fade"
          src={teaserImage?.src || ''}
          data-testid="image"
          alt={teaserImage?.alt}
          srcSet={teaserImage?.srcSet}
        />
      ) : (
        <WrappedImage
          loadingAnimation="fade"
          src={defaultImage.src}
          data-testid="image"
          alt={defaultImage.alt}
        />
      )}
    </ColumnItemPicture>
  );
};
