import React from 'react';
import { Button, ButtonGroup } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { messages } from '../../i18n/messages';
import { FallbackCTAS } from '../StockTeaserLayout/StockTeaserLayoutFallback';

const StyledButtonGroup = styled(ButtonGroup)``;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 49%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: 49%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    width: 49%;
  }
`;

export const StockResultButtonsFallback: React.FC<FallbackCTAS> = ({
  usedCarsCtaUrl,
  newCarsCtaUrl,
}) => {
  const newCarsButtonLabel = useI18n(messages.labelNewCars);
  const usedCarsButtonLabel = useI18n(messages.labelUsedCars);
  return (
    <>
      <StyledButtonGroup
        data-testid="Button group"
        variant="block-buttons"
        block
        spaceStackStart="xxl"
        spaceStackEnd="m"
      >
        <StyledButton href={newCarsCtaUrl} data-testid="ctaNewCarslabel" variant="primary">
          {newCarsButtonLabel}
        </StyledButton>
        <StyledButton href={usedCarsCtaUrl} data-testid="ctaUsedCarslabel" variant="secondary">
          {usedCarsButtonLabel}
        </StyledButton>
      </StyledButtonGroup>
    </>
  );
};
