import { ICarline } from './transformStockcarData';

export const getCarlineFiltersForUrl = (filters: string[]): string => {
  if (filters.length === 0) return '';
  const PREFIX = 'carline.';
  return filters.map((filter: string) => PREFIX + filter).join(',');
};

export const getArrayFromList = (stringList: string | undefined, seperator = ','): string[] => {
  if (!stringList) return [];
  if (!stringList.includes(seperator)) return [stringList];
  const listArray: string[] = stringList.split(seperator);
  if (listArray.length) {
    return listArray.map((item: string) => item.trim()).filter(Boolean);
  }
  return [];
};

export const getFilteredCarlines = (carlines: ICarline[] | null, filters: string[]): ICarline[] => {
  if (!carlines) return [];
  if (!filters.length) return carlines;
  return carlines.filter((carline) => filters.includes(carline.identifier?.id));
};
