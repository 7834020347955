import { gql } from '@oneaudi/onegraph-client';

export const carlineGroupsWithAmountOfStockCars = gql`
  query AvailableStockForMarketAndCarlines(
    $brand: String!
    $country: String!
    $language: String!
    $partnerInfo: PartnerInfo
    $includeNonConfigurables: Boolean
  ) {
    availableStock(
      marketIdentifier: { brand: $brand, country: $country, language: $language }
      partnerInfo: $partnerInfo
    ) {
      newCars {
        amount
        url
      }
      usedCars {
        amount
        url
      }
    }
    carlineStructureForStockCars(
      stockIdentifier: {
        stockCarsType: USED
        marketIdentifier: { brand: $brand, country: $country, language: $language }
      }
    ) {
      carlineGroups {
        identifier {
          id
        }
        name
        carlines(carlinesInput: { includeNonConfigurables: $includeNonConfigurables }) {
          identifier {
            id
          }
          name
          exteriorViews {
            beautyFront
          }
          consumptionAndEmission {
            summary {
              consumption
              emissionCO2
              footnoteReference
            }
          }
          availableStock(partnerInfo: $partnerInfo) {
            newCars {
              amount
              url
            }
            usedCars {
              amount
              url
            }
          }
        }
      }
    }
  }
`;
