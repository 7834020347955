export const messages = {
  headlinePartner: {
    defaultMessage: 'i18n: Headline Partner',
    id: 'nemo.ui.sc.vst.headlinepartner',
  },
  headlinePartnerGroup: {
    defaultMessage: 'i18n: Headline Partner Group',
    id: 'nemo.ui.sc.vst.headlinepartnergroup',
  },
  labelNewCars: {
    defaultMessage: 'i18n: Label New Cars',
    id: 'nemo.ui.sc.vst.labelnewcars',
  },
  labelUsedCars: {
    defaultMessage: 'i18n: Label Used Cars',
    id: 'nemo.ui.sc.vst.labelusedcars',
  },
  modelDropdownLabel: {
    defaultMessage: 'i18n: Dropdown Model Label',
    id: 'nemo.ui.sc.vst.dropdown.model',
  },
  modelDropdownLabelOptionAllCars: {
    defaultMessage: 'i18n: Dropdown Model Label All Cars',
    id: 'nemo.ui.sc.vst.dropdown.model.allcars',
  },
};
