import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { EditorContent } from '../types/editor';

export function getContent(contentService: ContentServiceV1): EditorContent {
  const content = contentService.getContent();

  if (content?.__type === 'aem-headless') {
    // map CF content to internal structure
    return content.fields as EditorContent;
  }

  // map FACE content to internal structure
  return content as EditorContent;
}
