import React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { messages } from '../../i18n/messages';

const StyledTextDesktop = styled(Text)`
  display: none;
  overflow: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: block;
    margin-top: var(${(props): string => props.theme.responsive.spacing.xl});
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    display: block;
    margin-top: var(${(props): string => props.theme.responsive.spacing.xxl});
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    display: block;
    margin-top: var(${(props): string => props.theme.responsive.spacing.xxxl});
  }
  @media (min-width: 1200px) {
    display: block;
    margin-top: var(${(props): string => props.theme.responsive.spacing.xl});
  }
`;

const StyledTextMobile = styled(Text)`
  overflow: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

export interface GrpupStock {
  groupStock: boolean;
  faceHeadLine?: string | null;
}

export const Headline: React.FC<GrpupStock> = ({ groupStock, faceHeadLine = null }) => {
  const headlinePartner = useI18n(messages.headlinePartner);
  const headlinePartnerGroup = useI18n(messages.headlinePartnerGroup);

  return (
    <>
      <StyledTextDesktop data-testid="headline" spaceStackEnd="l" variant="order1">
        {faceHeadLine || (!groupStock ? headlinePartner : headlinePartnerGroup)}
      </StyledTextDesktop>
      <StyledTextMobile data-testid="headline" spaceStackEnd="l" variant="order2">
        {faceHeadLine || (!groupStock ? headlinePartner : headlinePartnerGroup)}
      </StyledTextMobile>
    </>
  );
};
