import React from 'react';
import { Layout, LayoutItem, Select } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
// import { getRelatedElements, sendClickEvent } from '@volkswagen-onehub/audi-partner-utils';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { StockImageConsumptionValues } from '../StockImage/StockImageConsumptionValues';
import { messages } from '../../i18n/messages';
import { Headline } from '../Headline/Headline';
import { StockImage } from '../StockImage/StockImage';
import { StockResultButtons } from '../StockResultButtons/StockResultButtons';
import { StockCarLoader } from '../StockCarLoader/StockCarLoader';
import { StockTeaserLayoutFallback } from './StockTeaserLayoutFallback';
import { useStockcarsContext } from '../../hooks/UseStockcarsContext';
import { FeatureServiceContext } from '../../FeatureServiceContext';

export interface ResultState {
  showStock?: string;
}

export interface StockResult {
  // eslint-disable-next-line
  stockCars?: any;
}

export interface StockResultLoading {
  loading: boolean;
  error: boolean;
}

export interface StockTeaserLayoutProps {
  // eslint-disable-next-line react/no-unused-prop-types
  partnerId?: string;
  groupStock: boolean;
  // eslint-disable-next-line
  defaultModel?: string;
  newCarsCtaFallbackUrl?: string;
  usedCarsCtaFallbackUrl?: string;
  showStock?: string;
}

const StyledSelect = styled(Select)`
  select {
    // padding-right with the width of the svg arrow icon
    padding-right: 15px;
    text-overflow: ellipsis;
  }
`;

const ColumnItemContentSearch = styled.div`
  order: 2;
  width: 100%;
  @media (min-width: 1024px) {
    order: 1;
    width: 50%;
  }
`;

export const ALL_MODELS = 'allModels';

export const StockTeaserContent: React.FC<StockTeaserLayoutProps> = ({
  showStock,
  groupStock,
  newCarsCtaFallbackUrl,
  usedCarsCtaFallbackUrl,
}) => {
  const { trackingService } = React.useContext(FeatureServiceContext);

  const dropdownLabelModel = useI18n(messages.modelDropdownLabel);
  const dropdownModelOptionAllCars = useI18n(messages.modelDropdownLabelOptionAllCars);

  const { loadingState, loadingError, stockData, onCarChange, carId, carlineFilter, faceHeadLine } =
    useStockcarsContext();
  const handleCarSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onCarChange(event.target.value);
    const target = event.nativeEvent.target as HTMLSelectElement;
    const index = target.selectedIndex;
    const targetIndex = target[index] as HTMLOptionElement;
    const label = targetIndex.text;

    if (trackingService) {
      trackingService?.track({
        event: {
          eventInfo: {
            eventAction: 'navigation',
            eventName: 'vehicle stock teaser - select drop down item',
          },
          attributes: {
            componentName: 'vehicle-stock-teaser',
            label,
            elementName: 'drop down',
            value: label,
            relatedElements: [
              {
                elementName: 'drop down',
                elementValue: 'model',
              },
            ],
          },
        },
      });
    }
  };
  const carlinesAVailable = stockData?.filter(
    (car) => car.availableStock?.newCars.amount !== 0 || car.availableStock?.usedCars.amount !== 0,
  );

  return (
    <>
      <StockImage />
      <ColumnItemContentSearch>
        <Headline groupStock={groupStock} faceHeadLine={faceHeadLine} />
        <>
          {loadingError ? (
            <>
              <StockImageConsumptionValues />
              <StockTeaserLayoutFallback
                newCarsCtaUrl={newCarsCtaFallbackUrl}
                usedCarsCtaUrl={usedCarsCtaFallbackUrl}
              />
            </>
          ) : (
            <>
              {!loadingError && (
                <>
                  <Layout className="layout">
                    <LayoutItem className="layout_item" basis="100%">
                      {!!carlinesAVailable && carlinesAVailable?.length > 1 && (
                        <>
                          <StyledSelect
                            value={carId}
                            onChange={handleCarSelect}
                            inputId="select__model"
                            label={dropdownLabelModel}
                            spaceStackEnd="xl"
                            disabled={
                              loadingState ||
                              loadingError !== undefined ||
                              carlineFilter?.length === 1
                            }
                            required
                          >
                            {carlinesAVailable ? (
                              <>
                                <option value={ALL_MODELS}>{dropdownModelOptionAllCars}</option>
                                {carlinesAVailable.map((data) => {
                                  return (
                                    <option key={data.identifier.id} value={data.identifier.id}>
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <option>{dropdownModelOptionAllCars}</option>
                            )}
                          </StyledSelect>
                        </>
                      )}
                    </LayoutItem>
                  </Layout>
                  <Layout>
                    <LayoutItem spaceStackEnd="xl" spaceStackStart="xxs">
                      <StockImageConsumptionValues />
                    </LayoutItem>
                  </Layout>
                  {loadingError ? (
                    <StockTeaserLayoutFallback
                      newCarsCtaUrl={newCarsCtaFallbackUrl}
                      usedCarsCtaUrl={usedCarsCtaFallbackUrl}
                    />
                  ) : (
                    <StockResultButtons showStock={showStock} />
                  )}
                </>
              )}
              <StockCarLoader loadingState={loadingState} />
            </>
          )}
        </>
      </ColumnItemContentSearch>
    </>
  );
};
