import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';

export const trackFeatureAppReady = (trackingService: TrackingServiceV2, partnerId?: string) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: 'vehicle stock teaser - feature app ready',
        },
        attributes: {
          componentName: 'vehicle-stock-teaser',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          implementer: 2,
        },
        dealer: {
          dealerInfo: {
            dealerID: partnerId,
          },
        },
      },
    });
  }
};

export const registerImpressionTracking = (
  trackingService: TrackingServiceV2,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
  showStock: string | undefined,
) => {
  if (trackingService) {
    if (ref !== null) {
      trackingService.registerImpressionTracking(ref.current, () => {
        return {
          event: {
            eventInfo: {
              eventAction: 'impression',
              eventName: 'vehicle stock teaser - impression',
            },
            attributes: {
              componentName: 'vehicle-stock-teaser',
              label: '',
              targetURL: '',
              clickID: '',
              elementName: '',
              value: '',
              pos: '',
              relatedElements: buildRelatedElements(showStock),
            },
          },
        };
      });
    }
  }
};

export const getLinkType = (link?: string): string => {
  if (window !== undefined && (!window || !link || !link.includes(window.location.hostname))) {
    return 'exit_link';
  }

  return 'internal_link';
};

interface RelatedElement {
  elementName: string;
  elementValue: string;
}

export const buildRelatedElements = (showStock: string | undefined) => {
  const relatedElements: [RelatedElement] = [{ elementName: 'drop down', elementValue: 'model' }];

  if (showStock === 'both' || showStock === 'new') {
    relatedElements.push({
      elementName: 'cta',
      elementValue: 'car search - new',
    });
  }

  if (showStock === 'both' || showStock === 'used') {
    relatedElements.push({
      elementName: 'cta',
      elementValue: 'car search - used',
    });
  }
  return relatedElements;
};
